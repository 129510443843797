import { Injectable, OnDestroy, effect } from "@angular/core";
import { TierResult, getGPUTier } from "detect-gpu";
import { Howler } from "howler";
import { DeviceDetectorService } from "ngx-device-detector";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { Reverb, Soundfont } from "smplr";
import { ExhibitService } from "./services/exhibit.service";
import { KeyboardService, Legend } from "./services/keyboard.service";
import { ethereumAlbum } from "./util/album";
import { defaultDevice, defaultExhibit, gptUrl, openseaCollection, visualizerVideosMinimal } from "./util/constants";
import { getIPFSUrl } from "./util/helpers";
import { instruments } from "./util/instruments";
import { Device, FishInterface, MidiDevice, Spatial } from "./util/types";

declare let navigator: any;
declare let window: any;
declare let documentPictureInPicture: any;

let frame = 0;
let stereoPan = -1;
const positionalX = 0;

export type MediaMode = 'video' | 'trading';

@Injectable({
	providedIn: "root",
})
export class ArcadeService implements OnDestroy {
	private destroy$ = new Subject<void>();
	private mediaModeSubject = new BehaviorSubject<MediaMode>('video');

	fishSelected = new Subject<FishInterface>();
	pagerMessage = new Subject();
	playerMessage = new Subject();
	sceneMessage = new Subject();
	spatial = new Subject<Spatial>();
	videoRef: HTMLVideoElement;
	soundfont: any;
	synthAudioContext: AudioContext;
	player = {
		track: ethereumAlbum.tracks[0],
		title: "...",
		volume: 0.3,
		isPlaying: false,
		isMuted: false,
		isInit: false,
		isPIP: false,
		isSpatial: false,
		playlist: undefined,
		viz: "assets/video/dim_vx_80.mp4",
		sprites: "assets/audio/fsSprites.mp3",
		midiEnabled: false,
		midiInit: false,
		format: "webm",
		backupFormat: "mp3",
		cabinet: false,
		screenSaver: false,
	};
	midiInput: any;
	gpu: TierResult;
	device: Device;
	pipWindow: any;
	instrument: string;
	reverb: Reverb;
	gamepad: any;
	private currentExhibit = new BehaviorSubject<string>(defaultExhibit); // default-chain - remove once exhibit service is implemented
	private exhibitSettings = new BehaviorSubject<any>(null);
	public keyboardLegend: Legend;
	mediaMode$ = this.mediaModeSubject.asObservable();

	constructor(
		private deviceService: DeviceDetectorService,
		private keyboardService: KeyboardService,
		private exhibitService: ExhibitService
	) {
		this.animateSpatialAudio = this.animateSpatialAudio.bind(this);
		this.device = defaultDevice;

		// Subscribe to keyboard events with cleanup
		this.keyboardService.getKeyboardEvents()
			.pipe(takeUntil(this.destroy$))
			.subscribe(event => {
				switch(event) {
					case 'escape':
						this.sceneMessage.next("escape");
						break;
					case 'toggle-orbit-controls':
						this.playerMessage.next("toggle-orbit-controls");
						break;
					case 'spacebar':
						this.playerEvent().next("spacebar");
						break;
					case 'KeyN':
						this.playerEvent().next("KeyN");
						break;
					case 'key-left':
						this.playerMessage.next("key-left");
						break;
					case 'key-right':
						this.playerMessage.next("key-right");
						break;
					case 'key-up':
						this.playerMessage.next("key-up");
						break;
					case 'key-down':
						this.playerMessage.next("key-down");
						break;
					case 'key-f':
						this.playerMessage.next("key-f");
						break;
				}
			});
		
		this.keyboardLegend = this.keyboardService.legend()
		console.log(this.keyboardLegend);

		this.playerMessage.subscribe((event) => {
			// Analytics.record(event.name, event.attributes);
			// if (typeof event === "string") {
			// 	Analytics.record({ name: event });
			// }
			if (event === "track-onload") {
				this.transformVideo();
			}
		});

		this.fishSelected.subscribe((fish: FishInterface) => {
			
			// if (fish["3d"] starts with ipfs:// use helper function to get url
			if (fish["3d"] && fish["3d"].startsWith("ipfs://")) {
				const url = getIPFSUrl(fish["3d"]);

				fish["3d"] = url;
			}

			if (fish.attributes) {
				const hasVoice = fish.attributes.find((trait) => trait.trait_type === "voice");

				if (hasVoice) {
					// play the instrument of the fish on selection
					// this.playInstrumentWithString(hasVoice.value as (typeof instruments)[0]);
				}
			}
		});

		this.getDevice();
		this.gpuDetective();

		// Subscribe to exhibit changes
		effect(() => {
			const currentExhibit = this.exhibitService.currentExhibit();
			if (currentExhibit) {
				this.playerEvent().next(`exhibit-${currentExhibit}`);
			}
			this.currentExhibit.next(currentExhibit);
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getDevice() {
		this.deviceDetector();
		// this.gpuDetective();

		if (window.matchMedia("(display-mode: standalone)").matches) {
			this.device.isStandalone = true;
		} else if (window.standalone || this.device.deviceInfo.userAgent.indexOf("Electron") > -1) {
			this.device.isStandalone = true;
		}

		if (window.screen && window.screen.orientation && window.screen.orientation.type) {
			this.device.orientation = window.screen.orientation.type;
		} else if (window.orientation && window.orientation !== undefined) {
			if (window.orientation === 0) {
				this.device.orientation = "portrait-primary";
			} else if (window.orientation === 90) {
				this.device.orientation = "landscape-primary";
			} else if (window.orientation === -90) {
				this.device.orientation = "landscape-secondary";
			} else if (window!.orientation) {
				this.device.orientation = "portrait-secondary";
			}
		} else if (this.device.deviceInfo.orientation === "portrait" && window.orientation !== 0) {
			this.device.orientation = "portrait-primary";
		} else {
			this.device.orientation = "unknown";
		}

		if (document.visibilityState === "visible") {
			this.device.isTabVisible = true;
		} else if (document.visibilityState === "hidden") {
			this.device.isTabVisible = false;
		} else if (document.visibilityState === "prerender") {
			this.device.isTabVisible = false;
		} else if (document.visibilityState === "unloaded") {
			this.device.isTabVisible = false;
		}

		this.device.hasSpatialAudio = Howler.codecs("flac");

		this.device.isDesktop = this.device.deviceInfo.deviceType === "desktop";

		if (navigator.gpu !== undefined) {
			this.device.hasWebGPU = true;
		}

		if (this.device.isDesktop) {
			this.audioDetective();
		}

		if (window.innerWidth < 431) {
			this.device.isMobile = true;
		}

		if ("documentPictureInPicture" in window) {
			this.device.hasDocumentPIP = true;
		}

		if (navigator.gamepads && navigator.gamepads[0]) {
			this.device.hasStick = true;
		}

		if (this.device.isMobile) {
			let lastTouchEnd = 0;
			document.addEventListener(
				"touchstart",
				function (event) {
					if (event.touches.length > 1) {
						event.preventDefault();
					}
				},
				{ passive: false },
			);

			document.addEventListener(
				"touchend",
				function (event) {
					const now = new Date().getTime();
					if (now - lastTouchEnd <= 300) {
						event.preventDefault();
					}
					lastTouchEnd = now;
				},
				false,
			);
		}

		return this.device;
	}

	audioDetective() {
		this.device.hasSpatialAudio = Howler.codecs("flac");
	}

	async gpuDetective(glContext?: any): Promise<TierResult | undefined> {
		let gpuTier;
		const benchmarksURL = "./assets/benchmarks";
		try {
			if (glContext) {
				gpuTier = await getGPUTier({ benchmarksURL: benchmarksURL, glContext: glContext });
				this.gpu = gpuTier;
			} else {
				gpuTier = await getGPUTier({
					benchmarksURL: benchmarksURL,
				});
				this.gpu = gpuTier;
			}

			if (this.gpu.type === "FALLBACK") {
				this.gpu.tier = 3;
			}

			// if (!this.gpu.device) {
			// 	// this.gpu = await getGPUTier();
			// }

			this.device.gpu = this.gpu;
			return this.device.gpu;
		} catch (error) {
			console.error(error);
			// throw error;
			return;
		}
	}

	deviceDetector() {
		// let standalone = false;
		// if (window.standalone || window.matchMedia("(display-mode: standalone)").matches) {
		// 	standalone = true;
		// }
		this.device.isMobile = this.deviceService.isMobile();
		this.device.isTablet = this.deviceService.isTablet();
		this.device.isDesktop = this.deviceService.isDesktop();
		this.device.deviceInfo = this.deviceService.getDeviceInfo();
		return this.device.deviceInfo;
	}

	wasd() {
		this.playerMessage.next("wasd");
		this.device.isWasd = !this.device.isWasd;
	}

	selectedFish() {
		return this.fishSelected;
	}

	playerEvent() {
		return this.playerMessage;
	}

	pagerEvent() {
		return this.pagerMessage;
	}

	sceneEvent() {
		return this.sceneMessage;
	}

	transformVideo() {
		if (!this.videoRef) {
			this.videoRef = document.getElementById("videoPlayer") as HTMLVideoElement;
			if (!this.videoRef) {
				return;
			}
		}

		const p = visualizerVideosMinimal.src;

		const poster = visualizerVideosMinimal.poster;
		console.log(this.player.track);
		const newFileName = this.player.track.id + ".mp4";
		const newPath = p.replace(/[^/]*$/, newFileName);
		const posterFilename = this.player.track.id + "-poster.jpg";
		const posterPath = poster.replace(/[^/]*$/, posterFilename);
		console.log(newPath);
		this.videoRef.src = newPath;
		this.videoRef.poster = posterPath;
		// const geometry = new SphereGeometry(500, 60, 40);
		// geometry.scale(-1, 1, 1);

		// // Create a VideoTexture from the video element
		// const videoTexture = new VideoTexture(this.videoRef);
		// videoTexture.minFilter = LinearFilter;
		// videoTexture.magFilter = LinearFilter;
		// videoTexture.format = RGBFormat;

		// // Create a MeshBasicMaterial with the VideoTexture
		// const material = new MeshBasicMaterial({ map: videoTexture });

		// // Create a Mesh with the sphere geometry and material, then add it to the scene
		// const mesh = new Mesh(geometry, material);
		// this.scene.add(mesh);

		return;
	}

	flashMessageonDisplay(message: string, duration = 1000) {}
	
	// DONT DELETE ======= DONT DELETE ======= DONT DELETE ======= DONT DELETE

	// ============ MIDI ==============
	supportsMidi() {
		return typeof window !== "undefined" && typeof window.navigator !== "undefined" && navigator.requestMIDIAccess !== undefined;
	}

	// Entrypoint called from app. refactor to this service
	async isMidiInit(): Promise<MidiDevice | boolean> {
		// detect if browser supports WebMIDI
		if (!navigator.requestMIDIAccess) {
			// this.flashMessageonDisplay("WebMIDI is not supported in this browser");
			return false;
		}

		return navigator.requestMIDIAccess().then(
			async (midiAccess) => {
				const midi = midiAccess;
				const inputs = midiAccess.inputs;
				let response;

				if (inputs.size === 0) {
					this.flashMessageonDisplay("MIDI Not Found");
					return false;
				} else {
					// Iterate over the list of inputs and add an event listener to each one
					inputs.forEach(async (input) => {
						this.midiInput = input;
						this.flashMessageonDisplay(input.name + " " + input.type + " Connected");
						response = {
							name: input.name,
							type: input.type,
							manufacturer: input.manufacturer,
							state: input.state,
							version: input.version,
							id: input.id,
						};
						input.addEventListener("midimessage", (event) => {
							const midiMessage = event.data;
							const { message, messageType, data1, data2 } = this.translateMIDIMessage(midiMessage);
							this.flashMessageonDisplay(message, 0);
							this.playerEvent().next("midi-message");
							const note = { messageType, data1, data2 };
							this.playSynth(note);
						});
						await this.initSoundfont();

						// this.soundfont.listenToMidi(input);
					});
					this.player.midiInit = true;
					return response;
				}
			},
			(error) => {
				this.flashMessageonDisplay("MIDI error");
				console.error(error);
				return false;
			},
		);
	}

	async initSoundfont(): Promise<boolean> {
		try {
			if (!this.synthAudioContext) {
				this.synthAudioContext = new AudioContext();
			}

			// Only initialize if not already set
			if (!this.soundfont) {
				// Set default instrument if none specified
				const defaultInstrument = "applause"; 
				
				const soundfont = await new Soundfont(this.synthAudioContext, {
					instrument: defaultInstrument
				}).loaded();

				this.soundfont = soundfont;
				this.instrument = defaultInstrument;
				this.reverb = new Reverb(this.synthAudioContext);
				this.soundfont.output.addEffect("reverb", this.reverb, 0.5);
				this.playerEvent().next("voice:loaded");
			}

			return true;

		} catch (error) {
			console.error('Error initializing soundfont:', error);
			return false;
		}
	}

	// Translate a MIDI message into a human-readable string
	translateMIDIMessage(data) {
		// Split the status byte and data bytes
		const status = data[0];
		const data1 = data[1];
		const data2 = data[2];

		// Extract the channel from the status byte
		const channel = status & 0xf;

		// Extract the type of message from the status byte
		const messageType = status >> 4;

		// Initialize the human-readable message
		let message = "channel_" + channel + " ";

		if (messageType == 0x8) {
			message += "note " + data1 + " off with velocity " + data2;
		} else if (messageType == 0x9) {
			message += "note " + data1 + " on with velocity " + data2;
		} else if (messageType == 0xa) {
			message += "Polyphonic key " + data1 + " pressure " + data2;
		} else if (messageType == 0xb) {
			message += "control " + data1 + " with value " + data2;
		} else if (messageType == 0xc) {
			message += "program " + data1 + " with value " + data2;
		} else if (messageType == 0xd) {
			message += "pressure " + data1;
		} else if (messageType == 0xe) {
			// calculate the 14-bit pitch bend value
			const pitchBend = (data2 << 7) | data1;
			message += data1 + " " + data2 + " " + "pitchbend " + pitchBend;
		} else {
			message += "Unknown message type";
		}

		return { message, messageType, data1, data2 };
	}

	async toggleMidi() {
		if (!this.player.midiInit) {
			await this.isMidiInit();
		}

		if (this.player.midiEnabled) {
			this.player.midiEnabled = false;
			this.flashMessageonDisplay("MIDI Disabled");
		} else {
			this.player.midiEnabled = true;
			this.flashMessageonDisplay("MIDI Enabled");
		}

		return this.player.midiEnabled;
	}

	getMidiInstruments() {
		return instruments;
	}

	getMidiInstrument() {
		return this.soundfont;
	}

	setReverb(_reverb) {
		this.soundfont.output.sendEffect("reverb", _reverb);
	}

	async setMidiInstrument(_instrument) {
		if (!this.synthAudioContext) return;
		return new Soundfont(this.synthAudioContext, { instrument: _instrument }).loaded().then((soundfont) => {
			this.soundfont = soundfont;
			this.instrument = _instrument;
			this.reverb = new Reverb(this.synthAudioContext);
			this.soundfont.output.addEffect("reverb", this.reverb, 0.5);
			this.playerEvent().next("voice:loaded");
		});
		// this.pagerEvent().next("><(((o> voice_changed " + _instrument);
	}

	async playInstrumentWithString(_instrument: (typeof instruments)[0]) {
		if (!this.synthAudioContext) {
			this.synthAudioContext = new AudioContext();
		}

		if (_instrument !== this.instrument) {
			await this.setMidiInstrument(_instrument);
			this.soundfont.start({ note: "C4" });
		} else {
			this.soundfont.start({ note: "C6" });
		}
	}

	playSynth(_note) {
		if (!this.synthAudioContext || !this.soundfont) this.initSoundfont();
		if (_note.messageType === 9) {
			this.soundfont.start({ note: _note.data1, velocity: _note.data2 });
		} else if (_note.messageType === 8) {
			this.soundfont.stop({ note: _note.data1 });
		}
	}

	async playSynthNoteFromString(_note: string) {
		try {
			// Check if synth is initialized
			if (!this.synthAudioContext || !this.soundfont) {
				// Initialize if not ready
				await this.initSoundfont();
				
				// Double check initialization worked
				if (!this.soundfont) {
					console.warn('Could not initialize soundfont');
					return;
				}
			}

			// Play the note
			this.soundfont.start({ note: _note });
			
		} catch (error) {
			console.warn('Error playing synth note:', error);
		}
	}

	// ============ Bluetooth ==============

	bluetoothDetective() {
		const filters = [{ name: "Nimbus" }];
		navigator.bluetooth
			.requestDevice({ filters: filters })
			.then((device) => {})
			.catch((error) => {});
	}

	// ============ Gamepad ==============

	initGamepad() {
		// Detect if the gamepad is connected and select first available
		navigator.getGamepads().forEach((pad) => {
			if (pad) {
				this.gamepad = pad;
				this.device.hasStick = true;
				this.playerEvent().next("gamepad:connected");
				// break loop
				return;
			}
		});
	}

	gamepadEvents() {
		// Check if the Web Bluetooth API is supported
		if (navigator.bluetooth) {
			// Request permission to access the gamepad
			navigator.bluetooth
				.requestDevice({ filters: [{ services: ["gamepad"] }] })
				.then((device: any) => {
					// Connect to the gamepad
					return device.gatt.connect();
				})
				.then((gatt) => {
					// Get the gamepad's GATT service
					return gatt.getPrimaryService("gamepad");
				})
				.then((service) => {
					// Get the button press characteristic of the gamepad
					return service.getCharacteristic("button_press");
				})
				.then((characteristic) => {
					// Subscribe to the button press characteristic
					characteristic.startNotifications().then((char) => {
						char.addEventListener("characteristicvaluechanged", (event) => {
							// Handle the button press event here
						});
					});
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			console.error("Web Bluetooth API is not supported by this browser");
		}

		// const gamepad = navigator.getGamepads()[0];
		//
	}
	
	spatialEvents() {
		return this.spatial;
	}

	setSpatialAudio(_player: Spatial) {
		if (_player.type === "stereo") {
			Howler.stereo(_player.stereo.pan);
		} else if (_player.type === "stereo-wave") {
			stereoPan = Math.cos(frame / 100);
			if (!_player.init) {
				_player.init = true;
				this.animateSpatialAudio();
			}

			Howler.stereo(stereoPan);
		} else if (_player.type === "positional") {
			Howler.pos(_player.pos.x, _player.pos.y, _player.pos.z);
		} else if (_player.type === "orientation") {
			Howler.orientation(_player.orientation.x, _player.orientation.y, _player.orientation.z, _player.orientationUp.x, _player.orientationUp.y, _player.orientationUp.z);
		} else if (_player.type === "doom") {
			// Howler.pannerAttr({
			//   panningModel: "HRTF",
			//   refDistance: 0.8,
			//   rolloffFactor: 2.5,
			//   distanceModel: "exponential",
			// });
		}

		Howler.volume(_player.volume);
		this.player.isSpatial = true;
	}

	setStereoWave() {
		this.setSpatialAudio({
			type: "stereo-wave",
			pos: { x: positionalX, y: 0, z: 0 },
			orientation: { x: 0, y: 0, z: 0 },
			orientationUp: { x: 0, y: 0, z: 0 },
			volume: 1,
			stereo: {
				pan: 0,
				id: undefined,
			},
		});
	}

	// Use requestAnimationFrame to update the values on each frame
	private animateSpatialAudio() {
		if (this.player.isSpatial) {
			frame++;
			this.setSpatialAudio({
				type: "stereo-wave",
				pos: { x: positionalX, y: 0, z: 0 },
				orientation: { x: 0, y: 0, z: 0 },
				orientationUp: { x: 0, y: 0, z: 0 },
				volume: 1,
				stereo: {
					pan: 0,
					id: undefined,
				},
				init: true,
			});

			requestAnimationFrame(this.animateSpatialAudio);
		}
	}

	stopStereoWave() {
		this.setSpatialAudio({ type: "stereo", stereo: { pan: 0, id: undefined } } as Spatial);
		this.player.isSpatial = false;
	}

	// ============ Picture-in-Picture ==============

	async togglePictureInPicture() {
		const pip = document.pictureInPictureElement;

		if (this.player.isPIP) {
			this.pipWindow.close();
			return;
		}

		const pipElement = document.getElementById("fishtank");

		documentPictureInPicture.onenter = (event) => {
			this.player.isPIP = true;

			this.playerMessage.next("pip:enter");
		};

		// @ts-ignore
		this.pipWindow = await documentPictureInPicture.requestWindow({
			initialAspectRatio: 4 / 3,
			copyStyleSheets: true,
			width: 640,
			height: 480,
		});

		// Move the player to the Picture-in-Picture window.
		this.pipWindow.document.body.append(pipElement);
		this.pipWindow.document.title = "Picture-in-Picture Metaquarium";
		
		// Explicitly set margins to zero for PIP window
		this.pipWindow.document.body.style.margin = "0";
		this.pipWindow.document.body.style.padding = "0";
		this.pipWindow.document.documentElement.style.margin = "0";
		this.pipWindow.document.documentElement.style.padding = "0";
		
		// Add a style element to ensure margin is 0
		const styleEl = this.pipWindow.document.createElement('style');
		styleEl.textContent = `
			body, html {
				margin: 0 !important;
				padding: 0 !important;
				overflow: hidden !important;
			}
			:picture-in-picture {
				margin: 0 !important;
				padding: 0 !important;
			}
		`;
		this.pipWindow.document.head.appendChild(styleEl);

		this.pipWindow.addEventListener("unload", (event) => {
			this.player.isPIP = false;
			const playerContainer = document.querySelector("#scene") as HTMLElement;
			const pipPlayer = event.target.querySelector("#fishtank");
			playerContainer.append(pipPlayer);
			this.playerMessage.next("pip:exit");
		});

		this.pipWindow.addEventListener("resize", (event) => {
			this.playerMessage.next("pip:resize");
		});
	}

	// ============ Navigation ==============

	eject() {
		window.open("https://metaquarium.xyz", "_self");
	}

	shop() {
		window.open(openseaCollection, "_self");
	}

	chatGpt() {
		window.open(gptUrl, "_self");
	}

	// ============ Exhibit ==============
	getCurrentExhibit() {
		return this.currentExhibit.asObservable();
	}

	getCurrentExhibit2() {
		return this.exhibitService.currentExhibit();
	}

	async setExhibit(exhibit: string) {
		const currentExhibit = this.exhibitService.currentExhibit();
		
		// First unload current exhibit if exists
		if (currentExhibit) {
			this.playerEvent().next(`unload-${currentExhibit}`);
		}

		await this.exhibitService.setExhibit(exhibit);
	}

	getExhibitSettings() {
		return this.exhibitSettings.asObservable();
	}

	getCurrentMediaMode(): MediaMode {
		return this.mediaModeSubject.value;
	}

	setMediaMode(mode: MediaMode) {
		this.mediaModeSubject.next(mode);
	}
}
