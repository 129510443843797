import { environment } from "@env/environment";
import { Color, Group, Mesh, MeshPhysicalMaterial, Object3D } from "three";
import { ipfsUrl } from "./constants";
declare const window: any;

// export const randomYoutube = "https://www.youtube.com/watch?v=" + youtubes[Math.floor(Math.random() * youtubes.length)];

export const isDev = (): boolean => {
	if (environment.env === "dev") {
		return true;
	}

	return false;
};

export const isWeb2 = (): boolean => {
	return window.ethereum === undefined;
};

export const isLocal = (): boolean => {
	if (environment.env === "local") {
		return true;
	}

	return false;
};

export function fromWei(amountInWei: number, decimals = 18): number {
	return amountInWei / 10 ** decimals;
}

export function getIPFSUrl(url: string) {
	if (!url) return "";
	const protocol = url.split(":")[0];
	if (protocol !== "ipfs") return url;
	const path = url.replace(/^.*[\\/]/, "");
	const cid = url.split("/")[2];
	const extension = path.split(".")[1];
	const filename = path.split(".")[0];
	const newPath = `${filename}.${extension}`;
	return `${ipfsUrl}${cid}/${newPath}`;
}

export function getRBGColor() {
	const rgb1 = Math.floor(Math.random() * 255 + 1);
	const rgb2 = Math.floor(Math.random() * 255 + 1);
	const rgb3 = Math.floor(Math.random() * 255 + 1);
	return "rgb(" + rgb1 + "," + rgb2 + "," + rgb3 + ")";
}

export function materialRandomColor(material?: any) {
	const color = [getRandom(5), getRandom(5), getRandom(5), getRandom(5)];
	const metal = getRandom(5);
	const rough = getRandom(5);
	material.pbrMetallicRoughness.setBaseColorFactor(color);
	material.pbrMetallicRoughness.setMetallicFactor(metal);
	material.pbrMetallicRoughness.setRoughnessFactor(rough);
	return material;
}

export function getRandom(_x: number): number {
	return parseFloat(Math.random().toFixed(_x));
}

export function LightenDarkenColor(col, amt) {
	let usePound = false;

	if (col[0] == "#") {
		col = col.slice(1);
		usePound = true;
	}

	const num = parseInt(col, 16);

	let r = (num >> 16) + amt;

	if (r > 255) r = 255;
	else if (r < 0) r = 0;

	let b = ((num >> 8) & 0x00ff) + amt;

	if (b > 255) b = 255;
	else if (b < 0) b = 0;

	let g = (num & 0x0000ff) + amt;

	if (g > 255) g = 255;
	else if (g < 0) g = 0;

	// return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
	return "#00ff00";
}

export function getRandomBoolean() {
	return Math.random() < 0.5;
}

const getRandomDecimal = (_decimals: number) => {
	return parseFloat(Math.random().toFixed(_decimals));
};

export const getRandomDecimalInRange = (_min: number, _max: number, _decimals: number) => {
	return parseFloat((Math.random() * (_max - _min) + _min).toFixed(_decimals));
};

const getRandomNumberInRange = (_min: number, _max: number) => {
	return Math.floor(Math.random() * (_max - _min + 1) + _min);
};

// Returns a random number between min (inclusive) and max (exclusive)
const getRandomArbitrary = (min: number, max: number) => {
	return Math.random() * (max - min) + min;
};

//
const getRandomPositiveNegativeNumber = (_range: number) => {
	return Math.random() * _range * (Math.round(Math.random()) ? 1 : -1);
};

const WEEKDAYS = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const getWeekday = () => WEEKDAYS[new Date().getDay()];

const fractionate = (val, minVal, maxVal) => {
	return (val - minVal) / (maxVal - minVal);
};

const modulate = (val, minVal, maxVal, outMin, outMax) => {
	const fr = fractionate(val, minVal, maxVal);
	const delta = outMax - outMin;
	return outMin + fr * delta;
};

const avg = (arr) => {
	const total = arr.reduce(function (sum: any, b: any) {
		return sum + b;
	});
	return total / arr.length;
};

const max = (arr) => {
	return arr.reduce(function (a: number, b: number) {
		return Math.max(a, b);
	});
};

/** Round to the given number of significant figures (digits) */
export function roundToDigits(value: number, digits: number): number {
	if (value === 0) {
		return 0;
	}

	const x = 10 ** (Math.floor(digits) - Math.floor(Math.log10(Math.abs(value))) - 1);
	return Math.round(value * x) / x;
}

/** Convert degrees to radians */
export function degToRad(degrees: number): number {
	return (degrees * Math.PI) / 180.0;
}

/** Convert radians to degrees */
export function radToDeg(radians: number): number {
	return (radians * 180.0) / Math.PI;
}

/** Clamps 'value' to be within [lowerLimit, upperLimit] */
export function clamp(value: number, lowerLimit: number, upperLimit: number) {
	return Math.min(Math.max(value, lowerLimit), upperLimit);
}

const DIGITS = 4;

export function getOrbitString(orbit: { theta: number; phi: number }) {
	return `${roundToDigits(radToDeg(orbit.theta), DIGITS)}deg ${roundToDigits(radToDeg(orbit.phi), DIGITS)}deg auto`;
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
export function hslToRgb(h, s, l) {
	let r, g, b;

	if (s == 0) {
		r = g = b = l; // achromatic
	} else {
		const hue2rgb = function hue2rgb(p, q, t) {
			if (t < 0) t += 1;
			if (t > 1) t -= 1;
			if (t < 1 / 6) return p + (q - p) * 6 * t;
			if (t < 1 / 2) return q;
			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
			return p;
		};

		const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		const p = 2 * l - q;
		r = hue2rgb(p, q, h + 1 / 3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1 / 3);
	}

	return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   {number}  r       The red color value
 * @param   {number}  g       The green color value
 * @param   {number}  b       The blue color value
 * @return  {Array}           The HSL representation
 */
export function rgbToHsl(r, g, b) {
	(r /= 255), (g /= 255), (b /= 255);
	const max = Math.max(r, g, b),
		min = Math.min(r, g, b);
	let h, s;
	const l = (max + min) / 2;

	if (max == min) {
		h = s = 0; // achromatic
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}

		h /= 6;
	}

	return [h, s, l];
}

export function floatRGB(array) {
	const color = [parseInt(array.r) / 255, parseInt(array.g) / 255, parseInt(array.b) / 255, 1];
	return color;
}

export function rgbFloat(array) {
	const color = [Math.floor(array[0] * 255), Math.floor(array[1] * 255), Math.floor(array[2] * 255), 1];
	return color;
}

/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to string
 * @param {number} n number of chars to keep at front/end
 * @returns {string}
 */
export function getEllipsisTxt(str, n = 3) {
	let e = "...";
	let m = n;
	if (window.innerWidth < 768) {
		e = "..";
		m = n - 1;
	}

	if (str) {
		return `${str.slice(0, n)}${e}${str.slice(str.length - m)}`;
	}

	return "";
}

export function normalize(min, max, v) {
	return (v - min) / (max - min);
}

export function isBasicTrigger(triggerId) {
	return typeof triggerId === "string" || typeof triggerId === "number";
}

export function isTriggerPair(triggerId) {
	return Array.isArray(triggerId) && triggerId.length === 2;
}

export function getRandomRotation(): number {
	return Math.random() * Math.PI * 2;
}

export const tokenValue = (value, decimals) => (decimals ? value / Math.pow(10, decimals) : value);

export const randomizeColors = (material?: MeshPhysicalMaterial): MeshPhysicalMaterial => {
	const color = [getRandom(5), getRandom(5), getRandom(5), getRandom(5)];
	const reflectivity = getRandom(5);

	if (material) {
		material.color.setRGB(color[0], color[1], color[2]);
		// material.reflectivity = reflectivity;
		return material;
	}

	return new MeshPhysicalMaterial();
};

export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
export const getRandomColor = () => new Color(getRandomInt(0, 255) / 255, getRandomInt(0, 255) / 255, getRandomInt(0, 255) / 255);

export const disposeSchool = (_school: Group, _scene: any) => {
	_school.traverse((child: Object3D) => {
		if (child instanceof Mesh) {
			child.geometry?.dispose();
			if (Array.isArray(child.material)) {
				child.material.forEach((mat) => {
					mat.dispose();
					mat.map?.dispose();
				});
			} else {
				child.material?.dispose();
				child.material?.map?.dispose();
			}
		}
		if (child instanceof Object3D) {
			// _scene.remove(child);
		}
	});

	_school.clear();
	// _scene.remove(_school);
};

export { avg, fractionate, getRandomArbitrary, getRandomDecimal, getRandomNumberInRange, getRandomPositiveNegativeNumber, getWeekday, max, modulate, WEEKDAYS };

