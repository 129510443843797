import Variables, { getSynthwaveColor } from "@style/scss-variables";
import { Device, VideoSrcSet } from "./types";

export const defaultExhibit = "ethereum";

export const consoleHeight = parseInt(Variables.consoleHeight.substring(0, Variables.consoleHeight.length - 2));
export const consoleHeightMobile = parseInt(Variables.consoleHeightMobile.substring(0, Variables.consoleHeightMobile.length - 2));
export const navHeight = parseInt(Variables.navHeight.substring(0, Variables.navHeight.length - 2));

export const albumS3Url = "https://s3.us-west-2.amazonaws.com/metaquarium.art/albums/";
export const videoS3Url = "https://s3.us-west-2.amazonaws.com/metaquarium.art/video";
export const mp4S3Url = "https://s3.us-west-2.amazonaws.com/metaquarium.art/mp4";
export const dracoStaticPath = "https://www.gstatic.com/draco/versioned/decoders/1.5.7/";
export const awsBaseApi = "https://xi9uz1w484.execute-api.us-west-1.amazonaws.com/staging/";
export const charlotteSpritesUrl = "https://s3.us-west-2.amazonaws.com/metaquarium.art/audio/sprites/charlotteSprites.mp3";

export const DEFAULT_KTX2_TRANSCODER_LOCATION = "https://www.gstatic.com/basis-universal/versioned/2021-04-15-ba1c3e4/";
export const lambda3 = "https://rh2bsmk3kbyz7qi4qfd3imthwy0qztan.lambda-url.us-west-1.on.aws/";

export const openseaCollection = "https://opensea.io/collection/metaquarium";

const charlotteSprites = require("@assets/audio/charlotteSprites.json");
export const charlotteSpritesObject = charlotteSprites["sprite"];
export const ipfsGateways = {
	cloudflare: "https://cloudflare-ipfs.com/ipfs/",
	ipfs: "https://ipfs.io/ipfs/",
	pinata: "https://gateway.pinata.cloud/ipfs/",
	nftStorage: "https://nftstorage.link/ipfs/",
	filebase: "https://unfortunate-moccasin-blackbird.myfilebase.com/ipfs/",
};

export const ipfsUrl = ipfsGateways.filebase;

export const visualizerVideosMinimal = {
	src: videoS3Url + "/visualizers/1-86.mp4",
	poster: videoS3Url + "/visualizers/1-poster.jpg",
};

export const visualizerVideos = {
	src: videoS3Url + "/visualizers/1-86.mp4",
	poster: videoS3Url + "/visualizers/1-poster.jpg",
	srcSet: {
		"1x": {
			type: "video/mp4",
			src: videoS3Url + "/visualizers/1-86.mp4",
		},
		"1x-webm": {
			type: "video/webm",
			src: videoS3Url + "/visualizers/1-86.webm",
		},
		"2x": {
			type: "video/mp4",
			src: videoS3Url + "/visualizers/1-172.mp4",
		},
		"2x-webm": {
			type: "video/webm",
			src: videoS3Url + "/visualizers/1-172.webm",
		},
		"3x": {
			type: "video/mp4",
			src: videoS3Url + "/visualizers/1-258.mp4",
		},
		"3x-webm": {
			type: "video/webm",
			src: videoS3Url + "/visualizers/1-258.webm",
		},
	} as VideoSrcSet,
};

export const loaderVideos = {
	portrait: {
		poster: videoS3Url + "/loader/loader-5-merged-1080-1080-poster.jpg",
		width: 1080,
		height: 1920,
		currentTime: 0,
		p1080: {
			mp4: videoS3Url + "/loader/loader-5-portrait-1080.mp4",
			webm: videoS3Url + "/loader/loader-5-merged-1080-1080-vp9.webm",
			poster: videoS3Url + "/loader/loader-5-merged-1080-1080-poster.jpg",
		},
		p480: {
			mp4: videoS3Url + "/loader/loader-5-portrait-480.mp4",
			webm: videoS3Url + "/loader/loader-5-merged-480-480-vp9.webm",
			poster: videoS3Url + "/loader/loader-5-merged-480-480-poster.jpg",
		},
	},
	landscape: {
		poster: videoS3Url + "/loader/loader-5-merged-1280-1280-poster.jpg",
		width: 1920,
		height: 1080,
		currentTime: 0,
		p1080: {
			mp4: videoS3Url + "/loader/loader-5-landscape-1080.mp4",
			webm: videoS3Url + "/loader/loader-5-merged-1280-1280-vp9.webm",
			poster: videoS3Url + "/loader/loader-5-merged-1280-1280-poster.jpg",
		},
		p480: {
			mp4: videoS3Url + "/loader/loader-5-landscape-720.mp4",
			webm: videoS3Url + "/loader/loader-5-merged-1280-480-vp9.webm",
			poster: videoS3Url + "/loader/loader-5-merged-1280-480-poster.jpg",
		},
	},
};

export const threeLayers = { ENTIRE_SCENE: 0, BLOOM: 10, COMMERCIAL: 20, VOID: 30 };

export const bitmidi = {
	baseUrl: "https://bitmidi.com",
	random: "https://bitmidi.com/api/midi/random",
	all: "https://bitmidi.com/api/midi/all",
};

export const GIFs = [
	{
		filename: "oc1.gif",
		size: "cover",
	},
	{
		filename: "oc2.gif",
		size: "contain",
	},
	{
		filename: "oc3.gif",
		size: "cover",
	},
	{
		filename: "oc4.gif",
		size: "cover",
	},
	{
		filename: "oc5.gif",
		size: "cover",
	},
];
export const chromeApplicationId = "CA2D0C33";
export const chromeApplicationIdStyled = "5DF99FCE";
// export const chromeApplicationIdDefault = chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;

export const metaMaskDeeplink = "https://metamask.app.link/dapp/metaquarium.xyz";

export const discordInvite = "https://discord.gg/wGRYe69nXq";
export const discordGMInvie = "https://discord.gg/NWj9JM6q";
export const gptUrl = "https://chatgpt.com/g/g-ZKFQh7Wg9-metaquarium";

export const defaultDevice: Device = {
	isDesktop: false,
	isMobile: false,
	isTablet: false,
	isElectron: false,
	isTabVisible: false,
	isStandalone: false,
	isPIP: false,
	isWebGPU: false,
	isWasd: false,
	hasMIDI: false,
	hasHeadphones: false,
	hasSpatialAudio: false,
	hasStick: false,
	hasVR: false,
	hasXR: false,
	hasAirplay: false,
	hasCast: false,
	hasTouch: false,
	hasKeyboard: false,
	hasMouse: false,
	hasDocumentPIP: false,
	hasWebGPU: false,
	orientation: "unknown",
	gpu: {
		tier: 1,
		type: "BENCHMARK",
		isMobile: false,
		fps: 15,
		device: "AMD K6-2 3DNOW! 450MHz",
	},
	deviceInfo: {
		browser: "Chrome",
		browser_version: "90.0.4430.93",
		os: "Mac OS",
		os_version: "10.15.7",
		device: "Mac",
		deviceType: "desktop",
		userAgent: "Chrome 90.0.4430.93",
		orientation: "unknown",
	},
};

export const knobChainDetents = [
	{
		label: "Solana",
		color: getSynthwaveColor(),
		active: true,
		angle: 0,
	},
	{
		label: "Dogecoin",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "Bitcoin",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "Polygon",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "Ethereum",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "Base",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "LongBeach",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "MontereyBay",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
];

interface EthereumMilestone {
  id: string;
  date: string;
  title: string;
  description: string;
  type: 'launch' | 'hardfork' | 'eip' | 'event' | 'upgrade';
  eipNumber?: number;
  impact: 'high' | 'medium' | 'low';
  category: string;
}

export const ETHEREUM_MILESTONES: EthereumMilestone[] = [
  // Genesis and Early Days
  {
    id: 'genesis',
    date: '2015-07-30',
    title: 'Ethereum Genesis Block',
    description: 'The launch of the Ethereum mainnet, marking the birth of programmable blockchain',
    type: 'launch',
    impact: 'high',
    category: 'foundation'
  },
  {
    id: 'frontier',
    date: '2015-07-30',
    title: 'Frontier Release',
    description: 'First live release of Ethereum network',
    type: 'launch',
    impact: 'high',
    category: 'foundation'
  },
  // Major Protocol Upgrades
  {
    id: 'homestead',
    date: '2016-03-14',
    title: 'Homestead Upgrade',
    description: 'First planned protocol upgrade, introducing various network improvements',
    type: 'hardfork',
    impact: 'high',
    category: 'protocol'
  },
  {
    id: 'dao-fork',
    date: '2016-07-20',
    title: 'DAO Fork',
    description: 'Hard fork to address The DAO hack, leading to ETH/ETC split',
    type: 'hardfork',
    impact: 'high',
    category: 'governance'
  },
  // Important EIPs
  {
    id: 'eip-20',
    date: '2015-11-19',
    title: 'ERC-20 Token Standard',
    description: 'Standard interface for fungible tokens',
    type: 'eip',
    eipNumber: 20,
    impact: 'high',
    category: 'standards'
  },
  {
    id: 'eip-721',
    date: '2018-01-24',
    title: 'ERC-721 NFT Standard',
    description: 'Standard for non-fungible tokens',
    type: 'eip',
    eipNumber: 721,
    impact: 'high',
    category: 'standards'
  },
  // London and ETH 2.0
  {
    id: 'london',
    date: '2021-08-05',
    title: 'London Hard Fork',
    description: 'Introduced EIP-1559 fee burning mechanism',
    type: 'hardfork',
    impact: 'high',
    category: 'economics'
  },
  {
    id: 'merge',
    date: '2022-09-15',
    title: 'The Merge',
    description: 'Transition from Proof of Work to Proof of Stake',
    type: 'upgrade',
    impact: 'high',
    category: 'consensus'
  },
  // Recent Developments
  {
    id: 'shanghai',
    date: '2023-04-12',
    title: 'Shanghai Upgrade',
    description: 'Enabled staked ETH withdrawals',
    type: 'upgrade',
    impact: 'high',
    category: 'staking'
  },
  {
    id: 'cancun',
    date: '2024-03-13',
    title: 'Cancun-Deneb Upgrade',
    description: 'Introduced Proto-Danksharding and EIP-4844',
    type: 'upgrade',
    impact: 'high',
    category: 'scaling'
  }
];

// Utility functions for filtering and displaying milestones
export const getMilestonesByCategory = (category: string) => 
  ETHEREUM_MILESTONES.filter(m => m.category === category);

export const getMilestonesByType = (type: EthereumMilestone['type']) =>
  ETHEREUM_MILESTONES.filter(m => m.type === type);

export const getMilestonesByYear = (year: string) =>
  ETHEREUM_MILESTONES.filter(m => m.date.startsWith(year));



export const ethereumFacts: any[] = [
  {
    id: 1,
    year: 2013,
    category: 'genesis',
    fact: "Vitalik Buterin publishes the Ethereum whitepaper",
    impact: "Introduced the concept of a blockchain platform for decentralized applications",
    aquariumMetaphor: "Like the first fish venturing onto land, Ethereum evolved blockchain beyond simple transactions",
    video: {
			url: "https://www.youtube.com/watch?v=TDGq4aeevgY",
			id: "TDGq4aeevgY",
      title: "Vitalik Buterin: The Origins of Ethereum",
      channel: "Ethereum Foundation",
      description: "Vitalik explains the original vision for Ethereum"
    }
  },
  {
    id: 2,
    year: 2014,
    category: 'genesis',
    fact: "Ethereum Foundation established",
    impact: "Created organizational structure for Ethereum's development",
    aquariumMetaphor: "Establishing the coral reef that would support the entire ecosystem",
    video: {
      url: "https://www.youtube.com/watch?v=3x1b_S6Qp2Q",
      id: "3x1b_S6Qp2Q",
      title: "Early Days of Ethereum",
      channel: "Ethereum Foundation",
      description: "Documentation of the early Ethereum Foundation formation"
    }
  },
  {
    id: 3,
    year: 2015,
    category: 'genesis',
    fact: "Ethereum mainnet launches with Frontier release",
    impact: "First live version of Ethereum network",
    aquariumMetaphor: "The ocean coming to life with its first inhabitants",
    video: {
      url: "https://www.youtube.com/watch?v=gjwr-7PgpN8",
      id: "gjwr-7PgpN8",
      title: "Ethereum Frontier Launch",
      channel: "Ethereum Foundation",
      description: "Historical footage of Ethereum's initial launch"
    }
  },
  {
    id: 4,
    year: 2016,
    category: 'technical',
    fact: "The DAO hack occurs",
    impact: "Demonstrated importance of smart contract security",
    aquariumMetaphor: "A reminder that even in deep waters, predators lurk",
    video: {
      url: "https://www.youtube.com/watch?v=rNeLuBOVe8A",
      id: "rNeLuBOVe8A",
      title: "The DAO Hack Explained",
      channel: "Finematics",
      description: "Detailed explanation of The DAO hack and its implications"
    }
  },
  {
    id: 5,
    year: 2020,
    category: 'defi',
    fact: "DeFi Summer kicks off",
    impact: "Explosive growth in decentralized finance protocols",
    aquariumMetaphor: "A bloom season where new species rapidly emerged",
    video: {
      url: "https://www.youtube.com/watch?v=qFBYB4W2tqU",
      id: "qFBYB4W2tqU",
      title: "The Summer of DeFi",
      channel: "Finematics",
      description: "Overview of the DeFi boom in 2020"
    }
  },
  {
    id: 6,
    year: 2022,
    category: 'technical',
    fact: "The Merge to Proof of Stake",
    impact: "99.95% reduction in energy consumption",
    aquariumMetaphor: "Evolution from energy-intensive to sustainable ecosystem",
    video: {
			url: "https://www.youtube.com/watch?v=Nx-jYgI0QVI",
			id: "Nx-jYgI0QVI",
      title: "The Ethereum Merge Explained",
      channel: "Ethereum Foundation",
      description: "Comprehensive explanation of the PoW to PoS transition"
    }
  },
  {
    id: 7,
    year: 2017,
    category: 'nft',
    fact: "CryptoKitties launches, congesting the network",
    impact: "Demonstrated both potential and limitations of NFTs",
    aquariumMetaphor: "Like introducing a new species that changed the ecosystem",
    video: {
      url: "https://www.youtube.com/watch?v=mlpF-EWSkey",
      title: "CryptoKitties and the NFT Revolution",
      channel: "Coindesk",
      description: "The story of CryptoKitties and its impact on Ethereum"
    }
  },
  {
    id: 8,
    year: 2021,
    category: 'scaling',
    fact: "Layer 2 solutions gain traction",
    impact: "Provided scaling solutions for Ethereum",
    aquariumMetaphor: "Creating new depths for life to flourish",
    video: {
      url: "https://www.youtube.com/watch?v=BgCgauWVTs0",
      title: "Layer 2 Scaling Explained",
      channel: "Finematics",
      description: "Overview of Ethereum Layer 2 scaling solutions"
    }
  },
  {
    id: 9,
    year: 2020,
    category: 'governance',
    fact: "Introduction of EIP-1559",
    impact: "Changed Ethereum's fee market",
    aquariumMetaphor: "Establishing new rules for ecosystem balance",
    video: {
      url: "https://www.youtube.com/watch?v=MGemhK9t44Q",
      title: "EIP-1559 Explained",
      channel: "Finematics",
      description: "Detailed explanation of EIP-1559 and its effects"
    }
  },
  {
    id: 10,
    year: 2021,
    category: 'cultural',
    fact: "NFT Market Explosion",
    impact: "Brought mainstream attention to digital ownership",
    aquariumMetaphor: "A rapid evolution of new species and behaviors",
    video: {
      url: "https://www.youtube.com/watch?v=Oz9zw7-_vhM",
      title: "The NFT Phenomenon",
      channel: "The Defiant",
      description: "Documentary about the NFT boom"
    }
  }
];